<template>
    <template-layout>
        <template slot="drawers">
            <invoice-list-filters-drawer
                v-model="showFiltersDrawer"
                :defaultFilters="defaultFilters"
                :filters.sync="filters"
            />
        </template>

        <template slot="menu">
            <accounting-menu />
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Facturas</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="mr-4"
                                    icon
                                    @click="showFiltersDrawer = !showFiltersDrawer"
                                    v-on="on"
                                >
                                    <v-icon>mdi-tune</v-icon>
                                </v-btn>
                            </template>
                            <span>Filtrar</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <span
                            v-if="filters.createdAt"
                            class="font-weight-regular grey--text"
                        >Mostrando del {{ filters.createdAt.gte | moment('dddd D [de] MMMM YYYY') }} al {{ filters.createdAt.lte | moment('dddd D [de] MMMM YYYY') }}</span>
                    </v-col>

                    <v-col cols="6" md="4">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Total</h5>
                                <span class="display-1 black--text">{{ invoices.length }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6" md="4" v-if="stats">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Monto</h5>
                                <span
                                    class="display-1 black--text"
                                >{{ (stats.total ? stats.total : 0) | toCurrency }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="4" v-if="stats && stats[10.5]">
                        <v-card>
                            <v-card-text>
                                <h5
                                    class="subtitle-1 font-weight-regular grey--text"
                                >Productos %10.5</h5>
                                <span
                                    class="display-1 black--text"
                                >{{ stats[10.5].productsTax | toCurrency }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="4" v-if="stats && stats['21.0']">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Productos 21%</h5>
                                <span
                                    class="display-1 black--text"
                                >{{ stats['21.0'].productsTax | toCurrency }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="4" v-if="stats && stats['21.0']">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Servicios 21%</h5>
                                <span
                                    class="display-1 black--text"
                                >{{ stats['21.0'].servicesTax | toCurrency }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    :items="invoices"
                                    :loading="isLoading"
                                    :headers="headers"
                                    :items-per-page="100"
                                    no-data-text="No hay facturas para mostrar"
                                >
                                    <template
                                        v-slot:item.invoiceDate="{ item }"
                                    >{{ item.invoiceDate | moment('D/M/Y') }}</template>

                                    <template
                                        v-slot:item.invoiceFormattedNumber="{ item }"
                                    >{{ item.invoiceFormattedNumber }}</template>

                                    <template
                                        v-slot:item.customerName="{ item }"
                                    >{{ item.customerName | capitalize }}</template>

                                    <template
                                        v-slot:item.total="{ item }"
                                    >{{ item.total | toCurrency }}</template>

                                    <template v-slot:item.actions="{ item }">
                                        <v-btn icon @click.stop="onInvoiceSelected(item)">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
import qs from 'qs';
import TemplateLayout from '@/layout/TemplateLayoutWithMenu';
import InvoiceListFiltersDrawer from './InvoiceListFiltersDrawer';
import AccountingMenu from './AccountingMenu.vue';

export default {
    components: {
        TemplateLayout,
        InvoiceListFiltersDrawer,
        AccountingMenu
    },

    data() {
        return {
            isLoading: false,

            showFiltersDrawer: false,

            pagination: {
                sortBy: 'name',
                descending: false,
                rowsPerPage: -1
            },

            filters: {},
            defaultFilters: {
                createdAt: {
                    lte: this.$moment().set({ hour: 23, minute: 59, second: 59 }).toISOString(),
                    gte: this.$moment().set({ hour: 0, minute: 0, second: 0 }).toISOString()
                }
            },

            stats: [],
            invoices: [],

            headers: [
                { text: 'Factura #', sortable: false, value: 'invoiceFormattedNumber' },
                { text: 'Fecha', sortable: true, value: 'invoiceDate' },
                { text: 'Razón Social', sortable: false, value: 'customerName' },
                { text: 'Documento', sortable: false, value: 'customerDocument' },
                { text: 'Pedido #', sortable: true, value: 'orderId' },
                { text: 'Total', sortable: true, value: 'total' },
                { text: '', sortable: false, value: 'actions' }
            ]
        };
    },

    watch: {
        filters: {
            handler() {
                this.updateRouteQuery();
                this.fetchSales();
            },
            deep: true
        }
    },

    computed: {
    },

    mounted() {
        this.updateFiltersFromQuery();
    },

    methods: {
        updateFiltersFromQuery() {
            this.filters = { ...this.defaultFilters };

            for (let key of Object.keys(this.$route.query)) {
                if (this.$route.query[key]) {
                    this.filters[key] = this.$route.query[key];
                }
            }
        },

        updateRouteQuery() {
            let q = {};

            for (let key of Object.keys(this.filters)) {
                if (this.filters[key]) {
                    q[key] = this.filters[key];
                }
            }

            this.$router.replace({ name: 'invoiceList', query: q }).catch(() => { });
        },

        async fetchSales() {
            this.isLoading = true;
            this.invoices = [];

            try {
                this.stats = await this.$api.get('/api/invoices/stats', this.filters);

                let result = await this.$api.get('/api/invoices', this.filters);
                if (result) {
                    this.invoices = result.records;
                }
            }
            finally {
                this.isLoading = false;
            }
        },

        async onInvoiceSelected(invoice) {
            let data = await this.$api.getBlob('/api/invoices/' + invoice.id);

            var file = new Blob([data], { type: 'application/pdf' });
            var fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
