<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Filtros</span>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-select
                        label="Factura Tipo"
                        v-model="newFilters.invoiceType"
                        :items="optionsInvoiceType"
                        item-text="name"
                        item-value="value"
                        required
                    ></v-select>
                </v-col>

                <v-col cols="12">
                    <v-menu v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">

                        <template v-slot:activator="{ on }">
                            <v-text-field
                                label="Fecha"
                                color="accent"
                                v-model="dateRangeText"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="dateRange"
                            no-title
                            :first-day-of-week="1"
                            locale="es-AR"
                            range
                            event-color="green lighten-1"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-col cols="6">
                    <v-btn color="orange" dark block @click="onResetSelected">Resetear</v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn color="green" dark block @click="onApplySelected">Aplicar</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: {
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            filters: Object,
            orders: Array
        },

        data() {
            return {
                active: false,
                dateMenu: false,

                dateRange: [],

                newFilters: {},
                optionsInvoiceType: [
                    { name: 'Todos', value: null },
                    { name: 'A', value: 'A' },
                    { name: 'B', value: 'B' }
                ]
            };
        },

        computed: {
            dateRangeText() {
                let dates = [ ...this.dateRange ];
                dates.sort();

                if(dates.length > 1) {
                    return dates.map(d => this.$moment(d).format('DD/MM/YYYY')).join(' al ');
                }
                else {
                    return this.$moment(this.dateRange[0]).format('DD/MM/YYYY');
                }
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            filters: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.newFilters = { ...val };

                    if(!this.newFilters.invoiceType) {
                        this.newFilters.invoiceType = null;
                    }

                    if(this.newFilters.createdAt) {
                        this.dateRange = [
                            ...(this.newFilters.createdAt.gte ? [this.newFilters.createdAt.gte] : []),
                            ...(this.newFilters.createdAt.lte ? [this.newFilters.createdAt.lte] : [])
                        ];
                    }
                }
            }
        },

        methods: {
            onApplySelected() {
                let dates = this.dateRange.map(d => this.$moment(d));
                dates = dates.sort((a, b) => a.valueOf() - b.valueOf());

                if(dates.length === 1) {
                    dates.push(dates[0]);
                }

                this.newFilters.createdAt = {
                    gte: dates[0].set({ hour: 0, minute: 0, second: 0 }).toISOString(),
                    lte: dates[1].set({ hour: 23, minute: 59, second: 59 }).toISOString()
                };

                if(!this.newFilters.invoiceType) {
                    delete this.newFilters.invoiceType;
                }

                this.$emit('update:filters', this.newFilters);
                this.$emit('input', false);
            },

            onResetSelected() {
                let defaultFilters = JSON.parse(JSON.stringify(this.defaultFilters));
                this.$emit('update:filters', defaultFilters);
                this.$emit('input', false);
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>

<style>

</style>
